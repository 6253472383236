.editPopup {
  height: 100%;
}
.editPopup .el-container .el-aside {
  width: 340px !important;
  padding: 20px;
  line-height: 20px;
  height: 650px;
}
.editPopup .el-container .el-aside .shell {
  display: flex;
  margin-top: 0.5rem;
  /* justify-content: center; */
}
.editPopup .el-container .el-aside .shell .p1 {
  width: 30px;
}
.editPopup .el-container .el-aside .shell .p2 {
  width: 140px;
  margin-right: 30px;
}
.editPopup .el-container .el-aside .shell .p2:hover {
  cursor: pointer;
  color: #409eff;
}
.editPopup .el-container .el-aside .shell .p3,
.editPopup .el-container .el-aside .shell .p4 {
  width: 50px;
  text-align: center;
  color: #409eff;
  cursor: pointer;
}
.editPopup .el-container .el-aside .shell .el-input {
  width: 160px;
}
.editPopup .el-container .el-container .el-header {
  height: 44px !important;
  text-align: right;
  padding-top: 10px;
}
.editPopup .el-container .el-container .el-header .el-button {
  margin-right: 10px;
}
.editPopup .el-container .el-container .el-header .upload-demo .el-button {
  margin-right: 0;
}
.editPopup .el-container .el-container .el-main {
  height: 100%;
  padding-top: 10px;
}
.tsColor1 {
  color: #409eff !important;
}
.editPopup .el-container .el-header /deep/ .upload-demo .el-upload {
  border: 0;
  max-width: 100px !important;
  height: 32px !important;
  line-height: 32px;
  float: right;
}
